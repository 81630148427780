/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1 {
  padding-top: 2rem;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: snow;
}

.container {
  margin-bottom: 5rem;
}

.v2-container {
  padding: 1em;
}

a {
  color: rgb(34, 34, 34);
}

a:hover {
  color: #ecca74d8;
}

.home-container {
  width: 100%;
}

.body-container {
  background-color: white;
  padding: 1em;
  border-radius: 5px
}

.jenny-button {
  font-size: larger;
  padding: .3em;
  /* border-bottom: solid;
  border-right: solid;
  border-top: none;
  border-left: none; */
  border: none;
  background-color: #6c757d;
  /* border-color: rgb(224, 224, 224); */
  color: white;
  /* border-width: 2px;
  border-radius: 5px; */
  width: 100%;
}

.jenny-button:focus {
  /* background-color: transparent;
  border-color: #ecca74d8;
  color: #ecca74d8; */
  background-color: #332f27d8;
  outline: none;
}

.active {
  background-color: #332f27d8;
  /* margin-bottom: 1em; */
}

.jenny-button:hover {
  /* background-color: transparent; */
  background-color: #332f27d8;
  /* color: #ecca74d8; */
}

.btn-header {
  width: 100%!important;
}

.form-input {
  margin-bottom: 1rem;
}

.btn-submit {
  margin-right: auto;
}

form {
  min-width: 100%;
  padding: none;
}

.disabled {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  color: #CCC;
}

.pass {
  color: #bafc00;
}

.fail {
  color: #fc3200;
}

.skipped {
  color: rgb(233, 174, 66);
}

.btn-primary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-primary:hover {
  background-color: #332f27d8;
  border-color: #332f27d8;
}

.btn-primary:focus {
  /* border-color: rgb(233, 174, 66); */
  /* outline: 0 !important; */
}